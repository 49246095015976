#webFaqTableId th {
    text-align: center;
}

#webFaqTableId td:nth-child(1),
#webFaqTableId td:nth-child(2),
#webFaqTableId td:nth-child(3) {
    text-align: center;
}

#webFaqTableId td:nth-child(2),
#webFaqTableId td:nth-child(3) {
    min-width: 97px;
}

#webFaqTableId td:nth-child(5) {
    max-width: 400px;
}

#webFaqTableId td:nth-child(4) {
    max-width: 260px;
}


// ===============================================================
#fadTableId th {
    text-align: center;
}

#fadTableId td:nth-child(1),
#fadTableId td:nth-child(2) {
    text-align: center;
}
