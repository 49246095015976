#useractivitylog1Id th{
    text-align: center;
  }
  #useractivitylog1Id td:nth-child(1),
  #useractivitylog1Id td:nth-child(2),
  #useractivitylog1Id td:nth-child(3){
    text-align: center;
  }
  #useractivitylog1Id td:nth-child(4),
  #useractivitylog1Id td:nth-child(5),
  #useractivitylog1Id td:nth-child(6),
  #useractivitylog1Id td:nth-child(7),
  #useractivitylog1Id td:nth-child(8),
  #useractivitylog1Id td:nth-child(9){
    text-align: left;
  }

  #useractivitylog1Id td:nth-child(2){
    width:150px;
  }

  #useractivitylog1Id td:nth-child(3),
  #useractivitylog1Id td:nth-child(4),
  #useractivitylog1Id td:nth-child(5){
    width: 120px;
  }
  #useractivitylog1Id td:nth-child(6){
    width: 150px;
  }
  #useractivitylog1Id td:nth-child(9){
    width: 500px;
  }
  #useractivitylog1Id td:nth-child(1){
    width:70px;
  }