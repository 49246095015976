.table.dataTable th,
.table.dataTable td {
  background-color: #fff !important;
}

#managementTable th {
  text-align: center;
}
#managementTable td:nth-child(2) {
  text-align: center;
  width: 95px;
}

#managementTable td:nth-child(3) {
    text-align: center;
    width: 95px;
  }
