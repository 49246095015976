#followupTableId th {
    text-align: center;

}

#followupTableId td:nth-child(1),
#followupTableId td:nth-child(2),
#followupTableId td:nth-child(6) {
    text-align: center;
    width: 100px;
}

#followupTableId td:nth-child(4) {

    width: 125px;
}

#followupTableId td:nth-child(5) {
    width: 100px;
}

#followupTableId td:nth-child(3),
#followupTableId td:nth-child(8) {
    width: 150px;
}