#recruittable th {
  text-align: center;
}

#recruittable td:nth-child(1),
#recruittable td:nth-child(2),
#recruittable td:nth-child(6),
#recruittable td:nth-child(8) {
  text-align: center;
}

#recruittable td:nth-child(8) {
  text-align: right;
}