#offerLetterTableId th{
    text-align: center;
}
#offerLetterTableId td:nth-child(1),
#offerLetterTableId td:nth-child(2),
#offerLetterTableId td:nth-child(3),
#offerLetterTableId td:nth-child(5),
#offerLetterTableId td:nth-child(8){
    text-align: center;
}
#offerLetterTableId td:nth-child(2),
#offerLetterTableId td:nth-child(3){
    min-width: 97px;
}
#offerLetterTableId td:nth-child(6){
    max-width: 240px;
}
#offerLetterTableId td:nth-child(8){
    min-width: 118px;
}
#offerLetterTableId td:nth-child(9){
    min-width: 109px;
}