#pakagetable th{
    text-align: center;
  }
#pakagetable :nth-child(1),
#pakagetable :nth-child(2){
    text-align: center;
}

#pakagetable td:nth-child(7)
{
  text-align: right;
}

#pakagetable td:nth-child(8),
#pakagetable td:nth-child(9){
    text-align: right !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  /* Hiding the spin buttons in WebKit browsers */
  -webkit-appearance: none;
}
