#candidateTableId th {
    text-align: center;
}

#candidateTableId td:nth-child(1),
#candidateTableId td:nth-child(2),
#candidateTableId td:nth-child(11) {
    text-align: center;
}

#candidateTableId td:nth-child(7) {
    text-align: left;
}

#candidateTableId td:nth-child(10) {
    text-align: center;
    max-width: 120px;
}

.pagination {
    position: relative;
    z-index: 0;
}

#candidateTableId td:nth-child(4),
#candidateTableId td:nth-child(5),
#candidateTableId td:nth-child(6) {
    max-width: 120px;
}

.pagination-div {
    // justify-content: end;
    // display: flex;
    display: flex;
    justify-content: space-between;
    /* To create space between the Pagination and page-details */
    align-items: center;
    /* Align items vertically in the center */
}

.Mui-selected {
    /* background: #dee3fb; */
    color: #fff !important;
    background-color: #5b73e8 !important;
    border-color: #5b73e8 !important;
}

.MuiButtonBase-root {
    min-width: 30px !important;
    height: 30px !important;
    border-radius: 0px !important;
}

.followupBasictable {
    width: 100%;
}

.followupBasictable td {
    padding: 10px;
}

.my-is-invalid {
    border: #f46a6a thin solid !important;
    //padding-right: calc(1.5em + 0.94rem);
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.235rem) center;
}

.my-invalid-feedback {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 87.5%;
    color: #f46a6a;
}

#candidatefollowupTableId th {
    text-align: center;

}

#candidatefollowupTableId td:nth-child(1),
#candidatefollowupTableId td:nth-child(2),
#candidatefollowupTableId td:nth-child(4) {
    text-align: center;
    width: 100px;
}

#followupModal .modal-content {
    background-color: #f5f6f8 !important;
}