#webFeedbackTableId th {
  text-align: center;
}

#webFeedbackTableId td:nth-child(1),
#webFeedbackTableId td:nth-child(2) {
  text-align: center;
  width: 1px;
}

#webFeedbackTableId td:nth-child(3) {
  text-align: center;
  width: 1px;
}

#webFeedbackTableId td:nth-child(4) {
  text-align: center;
}

#webFeedbackTableId td:nth-child(5) {
  text-align: left;
}

#webFeedbackTableId td:nth-child(6) {
  text-align: center;
}

#webFeedbackTableId td:nth-child(6) {
  text-align: center;
}

#webFeedbackTableId td:nth-child(8) {
  text-align: center;
}


//   #webFeedbackTableId td:nth-child(2),
//   #webFeedbackTableId td:nth-child(3){
//     min-width: 97px;
//   }
//   #webFeedbackTableId td:nth-child(7){
//     text-align: left;
//   }
//   #webFeedbackTableId td:nth-child(7){
//     max-width: 240px;
//   }