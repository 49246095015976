#saveApplicationTableID th{
    text-align: center;
}
#saveApplicationTableID td:nth-child(1),
#saveApplicationTableID td:nth-child(2),
#saveApplicationTableID td:nth-child(3),
#saveApplicationTableID td:nth-child(7){
    text-align: center;
}
#saveApplicationTableID td:nth-child(5){
    max-width: 245px;
}
#saveApplicationTableID td:nth-child(4){
    max-width: 170px;
}