#placedApplicationTableID th{
    text-align: center;
}
#placedApplicationTableID td:nth-child(1),
#placedApplicationTableID td:nth-child(2),
#placedApplicationTableID td:nth-child(3),
#placedApplicationTableID td:nth-child(7){
    text-align: center;
}
#placedApplicationTableID td:nth-child(2){
    min-width: 97px;
}
// #placedApplicationTableID td:nth-child(7){
//     max-width: 185px;
// }
#placedApplicationTableID td:nth-child(5){
    max-width: 245px;
}
#placedApplicationTableID td:nth-child(4){
    max-width: 170px;
}