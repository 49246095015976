#subscriptionstable th {
    text-align: center;
  }
  
  #subscriptionstable td:nth-child(1),
  #subscriptionstable td:nth-child(2),
  #subscriptionstable td:nth-child(4),
  #subscriptionstable td:nth-child(5),
  #subscriptionstable td:nth-child(6),
  #subscriptionstable td:nth-child(8),
  #subscriptionstable td:nth-child(11){
    text-align: center;
  }

  #subscriptionstable td:nth-child(7){
    text-align: right;
  }
  
  