#managementTableId th{
    text-align: center;
}
#managementTableId td:nth-child(1),
#managementTableId td:nth-child(2),
#managementTableId td:nth-child(3){
    text-align: center;
}
#managementTableId td:nth-child(2),
#managementTableId td:nth-child(3){
    min-width: 97px;
}