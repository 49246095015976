#shortlistedApplicationTableID th{
    text-align: center;
}
#shortlistedApplicationTableID td:nth-child(1),
#shortlistedApplicationTableID td:nth-child(2),
#shortlistedApplicationTableID td:nth-child(3),
#shortlistedApplicationTableID td:nth-child(7){
    text-align: center;
}
#shortlistedApplicationTableID td:nth-child(2),
#shortlistedApplicationTableID td:nth-child(3)

#shortlistedApplicationTableID td:nth-child(4){
    max-width: 180px;
}
#shortlistedApplicationTableID td:nth-child(2){
    min-width: 97px;
}
#shortlistedApplicationTableID td:nth-child(5){
    max-width: 240px;
}
