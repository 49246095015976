
#instTableId th{
  text-align: center;
}
#instTableId td:nth-child(1),
#instTableId td:nth-child(2),
#instTableId td:nth-child(6),
#instTableId td:nth-child(10)
{
  text-align: center;
}
#instTableId td:nth-child(7),
#instTableId td:nth-child(8),
#instTableId td:nth-child(9){
  max-width: 120px;
}
.pagination{
  position: relative;
  z-index: 0;
}
#jobpostDataId1 th{
  text-align: center;
}
#jobpostDataId1 td:nth-child(1),
#jobpostDataId1 td:nth-child(2){
  text-align: center;
}
#jobpostDataId1 td:nth-child(2){
  min-width: 97px;
}