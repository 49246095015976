#viewApplicationTableID th {
    text-align: center;
}

#viewApplicationTableID td:nth-child(1),
#viewApplicationTableID td:nth-child(2),
#viewApplicationTableID td:nth-child(3),
#viewApplicationTableID td:nth-child(7) {
    text-align: center;
}

#viewApplicationTableID td:nth-child(2) {
    min-width: 97px;
}

#viewApplicationTableID td:nth-child(4) {
    max-width: 180px;
}

#viewApplicationTableID td:nth-child(5) {
    max-width: 245px;
}

#viewApplicationTableID td:nth-child(9) {
    max-width: 117px;
}

